import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="uncover"
export default class extends Controller {
  static targets = ['content']
  static values = { withComma: Boolean }

  connect() {
  }

  do(e) {
    e.preventDefault()

    e.currentTarget.remove()
    this.contentTarget.classList.remove('d-hidden')
    this.contentTarget.style.display = 'inherit'

    if (this.withCommaValue) {
      const prevEl = this.contentTarget.previousSibling;
      if (prevEl && prevEl.nodeType == Node.TEXT_NODE) {
        prevEl.textContent = prevEl.textContent.trim()

        const prevPrevEl = prevEl.previousSibling;
        if (prevPrevEl === null || !(prevPrevEl.nodeName === "BR")) {
          prevEl.textContent += ', '
        }
      }
    }
  }
}
