$.fn.spin = (opts) ->
  @each ->
    $this = $(this)
    data = $this.data()

    if data.spinner
      data.spinner.stop()
      delete data.spinner

    if opts != false and request = opts?.stopWhenComplete
      request.complete -> $this.spin false

    unless opts is false
      optsWithDefaults = $.extend color: $this.css('color'), opts
      data.spinner = new Spinner(optsWithDefaults).spin this
  this
