import PostPhotos from './photos.js'
import Map from './map.js'

export default class PostCard {
  constructor(el) {
    this.el = el

    const photosEl = this.el.querySelector('.post-photos')
    if (photosEl) { new PostPhotos(photosEl) }

    const mapEl = this.el.querySelector('.map')
    if (mapEl) { new Map(mapEl) }

    this.readMoreBtn = this.el.querySelector('.read-more')

    this.binds()
  }

  binds() {
    if (this.readMoreBtn) {
      this.readMoreBtn.addEventListener('click', (e) => this.activateReadMore(e))
    }
  }

  activateReadMore(e) {
    e.preventDefault()

    const richText = e.target.closest('.card-description')
    richText.classList.remove('cropped')
    richText.classList.add('full')

    return false
  }
}
