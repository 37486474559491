import { Controller } from "@hotwired/stimulus"
import renderTemplate from "../../src/shared/helpers/render_template";

export default class extends Controller {
  static values = {
    attrName: String,
    importantOnly: Boolean
  }

  connect() { }

  showMoreAttrs(event) {
    event.preventDefault();

    this.element.querySelectorAll('.d-hidden').forEach((el) => {
      el.classList.remove('d-hidden');
    });

    event.target.remove();
  }

  reset() {
    this.element.innerHTML = '';
  }

  render(eavAttrs) {
    let html = this.renderFields(eavAttrs.filter(attr => !this.importantOnlyValue || attr.important));
    this.element.innerHTML = html;

    if (this.element.querySelectorAll('.d-hidden').length) {
      const extraHTML = ' <button class="btn btn-link more-eav-attrs" data-action="click->form--eav-values#showMoreAttrs">Еще параметры</button> '
      this.element.insertAdjacentHTML('beforeend', extraHTML);
    }
  }

  renderFields(attrs) {
    let html = '';
    for (var i = 0; i < attrs.length; i++) {
      html += this.rendereField(attrs, i)
    }

    return html;
  }

  rendereField(attrs, i) {
    const attr = attrs[i];
    const templateName = "general/eav_value_fields/" + attr.attr_type;
    const namePrefix = this.attrNameValue + '[eav_values_attributes]' + '[' + i + ']';

    return renderTemplate(templateName, { attr: attr, namePrefix: namePrefix });
  }
}
