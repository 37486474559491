import { Controller } from "@hotwired/stimulus"
import fetchWithCsrf from "../../src/shared/helpers/fetch_json_with_csrf"
import maskPhone from '../../src/shared/helpers/mask_phone';

// Connects to data-controller="form--anonymous-phone"
export default class extends Controller {
  static targets = ["plain", "error"]
  static values = { id: String }

  connect() {
    this.plainTargets.forEach((plain) => {
      maskPhone(plain)
    })
  }

  async destroy(e) {
    e.preventDefault()

    if (this.idValue == "new") {
      this.destroyStub()
      return
    }

    const url = "/phones/" + this.idValue;

    let message;
    try {
      const response = await fetchWithCsrf(url, { method: 'DELETE' })
      if (response.ok) {
        this.element.remove();
      } else {
        message = 'Этот телефон нельзя удалить; возможно это единственный телефон одном или нескольких объявелниях.'
      }
    } catch (error) {
      message = 'Не удалось удалить телефон. Повторите попытку позже'
    }
    this.errorTarget.innerText = message
  }


  destroyStub() {
    this.removeElement();
    this.dispatch("destroy", { detail: {} });
  }

  removeElement() {
    this.element.remove()
  }
}
