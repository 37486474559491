import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form--phones"
export default class extends Controller {
  static targets = ["addButton", "phoneIds"]
  static values = {
    formObjectName: String,
    max: Number
  }
  static outlets = ["form--phone"]

  connect() {
    const form = this.element.closest("form")
    this.submitBtn = form.querySelector("input[type=submit], button[type=submit]");
    console.log(this.submitBtn)
  }

  disableSubmitButton() {
    this.submitBtn.disabled = true;
  }

  enableSubmitButton() {
    this.submitBtn.disabled = false;
  }

  formPhoneOutletConnected(outlet, element) {
    this.checkAddBtnPresence();
  }

  formPhoneOutletDisconnected(outlet, element) {
    this.checkAddBtnPresence();
  }

  phoneDeleted(id) {
    const input = this.phoneIdsTargets.find((el) => el.value === id);
    input.remove();
  }

  phoneAdded(id) {
    const input = `<input type="hidden" multiple="multiple"
        value="${id}"
        data-form--phones-target="phoneIds"
        name="${this.formObjectNameValue}[phone_ids][]">`
    this.element.insertAdjacentHTML('beforeend', input);
  }

  add(e) {
    e.preventDefault()

    const lastNewPhoneElement = this.formPhoneOutlets[this.formPhoneOutlets.length - 1].element
    const currentNewPhoneElement = this.cloneAndResetNewPhoneElement(lastNewPhoneElement);
    const parentElement = lastNewPhoneElement.parentNode;
    parentElement.insertBefore(currentNewPhoneElement, lastNewPhoneElement);
  }

  cloneAndResetNewPhoneElement(phoneElement) {
    const currentNewPhoneElement = phoneElement.cloneNode(true);
    currentNewPhoneElement.classList.remove('d-hidden');
    currentNewPhoneElement.querySelectorAll('[name*="phone["').forEach((input) => {
      const tempId = new Date().getTime();
      input.value = '';

      input.name = input.name.replace(/\d+/, tempId);
      input.id = input.id.replace(/\d+/, tempId);
    });

    return currentNewPhoneElement;
  }

  checkAddBtnPresence() {
    if (!this.hasAddButtonTarget) return;

    if (this.formPhoneOutlets.length >= (this.maxValue + 1)) {
      this.addButtonTarget.classList.add('d-hidden');
    } else {
      this.addButtonTarget.classList.remove('d-hidden');
    }
  }
}
