import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form--title-seo-prefix"
export default class extends Controller {
  static targets = ["select", "customInput"]

  connect() {
  }

  toggle(event) {
    event.preventDefault()

    event.currentTarget.classList.toggle('active')

    this.selectTarget.classList.toggle('hide')
    this.selectTarget.toggleAttribute('disabled')

    this.customInputTarget.classList.toggle('hide')
    this.customInputTarget.toggleAttribute('disabled')
  }
}
