import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form--category"
export default class extends Controller {
  static outlets = ["form--eav-values"]
  static values = { urlPath: String, licenseAttrName: String }
  static targets = ["license"]

  connect() { }

  changed(event) {
    const value = event.target.value;

    if (value && value.length) {
      const url = this.urlPathValue + '/' + value;

      fetch(url)
        .then((r) => r.json())
        .then((json) => { this.updated(json) })
    } else {
      this.reset();
    }
  }

  reset() {
    this.formEavValuesOutlet.reset();
  }

  updated(category) {
    this.formEavValuesOutlet.render(category.eav_attrs);
    this.checkLicenseState(category);
  }

  checkLicenseState(category) {
    if (this.hasLicenseTarget && this.licenseAttrNameValue) {
      const licenseRequired = category[this.licenseAttrNameValue];
      if (licenseRequired) {
        this.licenseTarget.classList.remove('-hidden');
      } else {
        this.licenseTarget.classList.add('-hidden');
      }
    }
  }
}
