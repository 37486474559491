import { Controller } from "@hotwired/stimulus"
import renderTemplateHTML from '../../src/shared/helpers/render_template_html'
import isElementInViewport from '../../src/shared/helpers/is_element_in_viewport'
// import fetchJsonWithCsrf from '../../src/shared/helpers/fetch_json_with_csrf'
import { FetchRequest } from '@rails/request.js'
// Connects to data-controller="form--placements"
export default class extends Controller {
  static outlets = ["form--check-limit"]
  static targets = ['checkbox', 'regionAreas', 'regions']
  static values = {
    data: Object,
    localityNumber: String,
    placementNumber: String,
    postNumber: String,
    displayCheckAllRegionValue: Boolean
  }

  createUrl(number) { return `/post/${number}/placements` }
  destroyUrl(number) { return `/placements/${number}` }

  connect() { }

  toggle(e) {
    const checkbox = e.currentTarget
    const localityNumber = checkbox.dataset.localityNumber

    if (checkbox.checked) {
      this.createPlacement(localityNumber)
    } else {
      this.destroyPlacement(localityNumber)
    }
  }

  openRegionArea(e) {
    const btn = e.currentTarget
    const regionArea = this.findRegionArea(btn.dataset.regionAreaId)
    const html = renderTemplateHTML('general/post_posting/placements/cities', {
      title: regionArea[0]['name'],
      cities: regionArea[1]
    })[0]

    btn.setAttribute('disabled', 'disabled')
    this.regionAreasTarget.append(html)
    this.highlight(html, '#ffa')
    this.formCheckLimitOutlet.update()
  }

  openRegion(e) {
    const btn = e.currentTarget
    const region = this.findRegion(btn.dataset.regionId)
    const html = renderTemplateHTML('general/post_posting/placements/cities', {
      title: region[0]['name'],
      cities: region[1]
    })[0]


    btn.setAttribute('disabled', 'disabled')
    this.regionsTarget.append(html)
    this.highlight(html, '#ffa')
    this.formCheckLimitOutlet.update()
  }

  toggleAllInBlock(e) {
    const isChecked = e.currentTarget.checked
    const block = e.currentTarget.closest('.placements-block')

    block.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
      if (!checkbox.disabled && checkbox.checked !== isChecked) {
        checkbox.click()
      }
    })
    this.formCheckLimitOutlet.update()
  }

  findRegionArea(id) { return this.dataValue.region_areas.find(el => el[0]['id'] == id) }

  findRegion(id) { return this.dataValue.regions.find(el => el[0]['id'] == id) }

  highlight(el, color) {
    if (!isElementInViewport(el)) {
      window.scrollTo({
        top: el.offsetTop - 60,
        behavior: 'smooth'
      })
    }

    setTimeout(() => {
      el.style.margin = '0 -10px'
      el.style.padding = '0 10px'
      el.style.backgroundColor = color
      setTimeout(() => {
        el.style.backgroundColor = 'transparent'
        el.style.margin = ''
        el.style.padding = ''
      }, 500)
    }, 500)
  }

  async createPlacement(localityNumber) {
    const request = new FetchRequest('post', this.createUrl(this.postNumberValue), {
      body: JSON.stringify({ locality_number: localityNumber })
    })

    try {
      const response = await request.perform()
      const json = await response.json
      if (response.ok) {
        this.successfullyCreated(json)
      } else {
        this.createFailed(json)
      }
      this.updatePlacementLimitsAfterComplete(json)
    } catch (error) {
      alert('Не удалось добавить публикацию. Попробуйте перезагрузить страницу и повторить снова')
    }
  }

  async destroyPlacement(localityNumber) {
    const checkbox = this.findInputByLocalityNumber(localityNumber)
    const placementNumber = checkbox.dataset.placementNumber
    if (!placementNumber) return

    const request = new FetchRequest('delete', this.destroyUrl(placementNumber))

    try {
      const response = await request.perform()
      const json = await response.json
      if (response.ok) {
        this.successfullyDestroyed(json)
      } else {
        this.destroyFailed(json)
      }
      this.updatePlacementLimitsAfterComplete(json)
    } catch (error) {
      alert('Не удалось удалить публикацию. Попробуйте перезагрузить страницу и повторить снова')
    }
  }

  successfullyCreated(response) {
    const pl = response.placement
    const checkbox = this.findInputByLocalityNumber(pl.locality.number)
    const label = checkbox.parentNode
    checkbox.dataset["placementNumber"] = pl.number
    this.clearStateClasses(label)
    label.classList.add(`-${pl.status_name}`)
  }

  createFailed(json) {
    const checkbox = this.findInputByLocalityNumber(json.locality_number)
    checkbox.checked = false
  }

  successfullyDestroyed(response) {
    const pl = response.placement
    const checkbox = this.findInputByLocalityNumber(pl.locality.number)
    const label = checkbox.parentNode
    checkbox.dataset["placementNumber"] = pl.number
    this.clearStateClasses(label)
  }

  destroyFailed(json) {
    const checkbox = this.findInputByPlacementNumber(json.placement.number)
    checkbox.checked = true
  }

  updatePlacementLimitsAfterComplete(json) {
    if (json.limits) {
      this.formCheckLimitOutlet.set(json.limits)
    }
  }

  findInputByLocalityNumber(localityNumber) {
    return this.checkboxTargets.find(el => el.dataset.localityNumber === localityNumber)
  }

  findInputByPlacementNumber(placementNumber) {
    return this.checkboxTargets.find(el => el.dataset.placementNumber === placementNumber)
  }

  clearStateClasses(label) {
    label.classList.forEach(className => {
      if (className.match(/^\-/)) {
        label.classList.remove(className)
      }
    });
  }
}
